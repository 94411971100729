<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
	<div class="sign-in bg-gray-3">
		
		<a-row type="flex" :gutter="24" justify="space-around" align="middle" style="padding-top: 100px;">

			<a-col :span="24" :md="14" :lg="14" :xl="{span: 14, offset: 0}" class="col-form mt-20">
				
				<a-card :bordered="false" class="card-info px-5 mx-0 pt-20">
					<a-row :gutter="[24,24]">
						<a-button id="btnBordered" type="text" class="primary ml-15 mb-20" v-if="current > 0 && current != 2" @click="previousScreen"><a-icon type="arrow-left" theme="outlined" /> &nbsp;&nbsp; Back  </a-button>
						<a-col :span="24">
							<a-row :gutter="24">
								
								<a-col :span="24" :md="24" :lg="24">

									<a-row type="flex" :gutter="24" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==0">
										<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

											<a-row ty :gutter="24" class="text-center">
												<a-col :span="24">
													<label class="text-center mt-0 pt-0" style="font-size: 18px;">
														<strong>Verify token sent to {{ $route.params.username }}</strong>
													</label>
												</a-col>
											</a-row>

											<a-alert
												class="mt-10"
												v-if="errorToken.show"
												:message="errorToken.message"
												type="error"
												closable
												:after-close="handleClose"
												show-icon 
											/>

											<a-form
												id="components-form-demo-normal-login"
												:form="tokenForm"
												class="login-form mt-20 pt-20"
												@submit="handleSubmitToken"
												:hideRequiredMark="true">

												<a-form-item class="mb-10" label="Token" :colon="false">
													<a-input 
														type="number"
														v-decorator="[ 'token',
														{ rules: [{ required: true, message: 'Please input verification code!' }] },
														]" 
														placeholder="Enter verification code" />
												</a-form-item>

												<a-row>
													<a-col :span="24" class="text-center pb-5">
														Didn't receive token? <a-button id="textBtn" class="text-primary px-0 mx-0 pl-2" type="text" @click="resendToken" style="text-decoration: none !important; font-size: 14px;">
															Resend token
														</a-button>
													</a-col>
												</a-row>
												
												<a-form-item class="mt-2 text-center mt-20 pt-5">
													<a-button :loading="isLoadingSubmission" type="primary" style="width: 100px !important;" block html-type="submit" class="login-form-button mt-10 text-white" >
														Verify
													</a-button>
												</a-form-item>
												
											</a-form>
										</a-col>
									</a-row> 
												
								</a-col>
							</a-row>
						</a-col>
					</a-row>

					<a-row :gutters="24" class="pt-10 pb-10">
						<a-col :span="24" class="text-center pb-5">
							Already have an account? <a class="text-primary" href="/sign-in" style="text-decoration: none !important;">
								<strong>Sign in</strong>
							</a>
						</a-col>
					</a-row>

					

					
				</a-card>
			</a-col>


		</a-row>
		
	</div>
</template>

<script>
	import VueJwtDecode from 'vue-jwt-decode'
	import VueCountryCode from "vue-country-code-select";
    import { notification } from 'ant-design-vue';

	export default ({
		components: {
			VueCountryCode
		},

		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				// form: null,
				// formPassword: null,

				

				genders: [
					{ uuid: false, name: "Male" },
					{ uuid: true, name: "Female" },
				],

				current: 0,
				steps: [
					{
						title: 'Verify Account',
						content: 'Third-content',
					},
				],

				academicEducationSystems: [],
				nonAcademicEducationSystems: [],

				studentRole: '',
				parentRole: '',
				tutorRole: '',
				instituteRole: '',

				loadingRoles: false,

				newUser: {
					firstName: null,
					lastName: null,
					emailAddress: null,
					phoneNumber: null,
					password: null,
					passwordConfirmation: null,
					gender: null,
					dateOfBirth: null,
					countryCode: null,
					latLon: null,
					role: null,

					username: null,
					countryPhoneCode: null,
					countryIsoCode: null,
					educationSystemUuid: null,
					academicEducationSystemUuid: null,
					nonAcademicEducationSystemUuid: null,


					usernameLoading: false,
				},
				
				error: {
					message: null,
					show: false,
				},

				errorUsername: {
					message: null,
					show: false,
				},

				errorToken: {
					message: null,
					show: false,
				},

				errorPassword: {
					message: null,
					show: false,
				},

				resetPassword: {
					token: null,
					password: null,
					passwordConfirmation: null,
				},

				learning: {
					uuid: null,
					password: null,
					passwordConfirmation: null,
				},

				username: null,

				

				isLoading: false,
				isLoadingToken: false,
				isLoadingPassword: false,
				isLoadingSubmission: false,

				
			}
		},
		beforeCreate() {
			this.tokenForm = this.$form.createForm(this, { name: 'token_details' });
		},
		created() {
			this.resendToken();
		},
		methods: {

			next() {
				this.current++;
			},

			previousScreen() {
				if(this.current > 0) {
					this.current--;
				}
			},




			onSubmitToken() {
				this.isLoadingToken = true;
				this.errorToken.message = null;
				this.errorToken.show = false;

				if(this.resetPassword.token != null && this.resetPassword.token.length == 6) {
					this.current++;
				}else{
					this.errorToken.message = "Invalid token";
					this.errorToken.show = true
				}
			},

			onSelectCountry({name, iso2, dialCode}) {
				// console.log(name)
				// console.log(iso2)
				this.newUser.country = name.toLowerCase();
				this.newUser.countryPhoneCode = dialCode;
			},

			handleClose() {
				this.error.show = false;
			},

			handleCloseToken() {
				this.errorToken.show = false;
			},

			handleClosePassword() {
				this.errorPassword.show = false;
			},




			handleSubmitToken(e) {
				e.preventDefault();

				this.tokenForm.validateFields((err, values) => {
					if ( !err ) {

						this.errorToken.show = false;
						this.errorToken.message = null;

						this.isLoadingSubmission = true;

						console.log(values)
						const pin = values.token;
						const username = this.$route.params.username;
						// const passwordConfirmation = values.passwordConfirmation;

						let url = `${this.$BACKEND_URL}/auths/verify-pin`;

						this.$AXIOS.post(url, { username, pin }).then(async(response) => {
							if (response.status >= 200 && response.status < 210) {

								this.notify(response.data.message, 'success')

								await localStorage.setItem("user_token", response.data.data.token)

								let userInfo = VueJwtDecode.decode(response.data.data.token);
								if(userInfo != null) {
									await localStorage.setItem("user_details", JSON.stringify(userInfo.data))
								

									if(userInfo.data.role != null && userInfo.data.role.code == 'student') {
										this.$router.push("/onboarding-student")
									}

									if(userInfo.data.role != null && userInfo.data.role.code == 'parent') {
										this.$router.push("/onboarding-parent")
									}

									if(userInfo.data.role != null && userInfo.data.role.code == 'teacher') {
										this.$router.push("/onboarding-tutor")
									}

									if(userInfo.data.role != null && userInfo.data.role.code == 'admin') {
										this.$router.push("/onboarding-institute")
									}

								}
								
							}
							this.isLoadingSubmission = false;
						}).catch((err) => {
							console.log(err)
							this.isLoadingSubmission = false;
							
							this.errorToken.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
							this.errorToken.show = true
						});

					}
				});
			},



			resendToken() {
				// this.isLoadingToken = true
					
				let url = `${this.$BACKEND_URL}/auths/resend-pin`;

				let username = this.$route.params.username
				
				this.$AXIOS.post(url, { username }).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.notify(`Verification token has been sent to ${username}`, 'success')
						
					}
					this.isLoadingToken = false;
				}).catch((err) => {
					console.log(err)
					// this.isLoadingToken = false;
					
					this.errorToken.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorToken.show = true
				});
			},

			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
            },
		},
	})

</script>

<style lang="scss" scoped>
	body {
		background-color: #ffffff;
	}
	.ant-steps-item-process .ant-steps-item-icon { background: red; }
	#signinBtn, #signinBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
	#submitUsernameBtn, #submitUsernameBtn:hover, #submitTokenBtn, #submitTokenBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
	#backBtn, #backBtn:hover {
		color: #632163 !important;
		border-color: #632163 !important;
		background-color: white !important;
	}

	.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: red !important;
}

.custome-step .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: red !important;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
//    background-color: #632163 !important;
   color: #ffffff;
}

.ant-steps-item-process .ant-steps-item-icon {
    border-color: #632163 !important;
    background: #632163 !important;
}

#divId {
	display: inline-block !important;
}
</style>
